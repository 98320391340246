/* .App {
  text-align: center;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background: #060912;
  color : #ffffff;
}


/* secondary-color : rgb(229 229 232 / 0.72); */

.menu-option span{
  color : rgb(229 229 232 / 0.72);
}

.menu-option .active span {
  color : #ffffff;
}

.search-form input {
    background-color: rgb(36 40 62 / 1);
    border: 1px solid #ced4da;
    border-radius: .25rem;
    padding-left: 12px;
}

.search-form button {
    background: #ddd;
    font-size: 17px;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 4px;
    top : 4px;

    padding-left: 8px;
    padding-right: 8px;
    border-radius: 2px;
    color: #24283e;
}

.token-image {
    max-height: 80px;
    max-width:  80px;
}

.token-image-ic {
  max-height: 40px;
  max-width:  40px;
}

.border-rebrand-chain-solana {
  --tw-border-opacity: 1;
  border-color: #4b5563;
}


.x-board {
  background-color: rgb(27 29 40);
  border: 1px solid rgb(36 40 62 / 1);
}

.x-border{
  border: 1px solid rgb(36 40 62 / 1);
}

.tr-token{
   height : 48px;
}

.s-board{
  background-color: #FFEB3B;
  border: 1px solid rgb(36 40 62 / 1);
  color: black;
}





/**** RADAR ****/
.radar {
  position: relative;
  overflow: hidden;
  width: 300px;
  aspect-ratio: 1;
  background: #000 url(disk.png) center / cover;
  border-radius: 50%;

  margin-left: calc(50% - 150px);
  margin-top: 35px;
  z-index:  -1;
}

.beam {
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  aspect-ratio: inherit;
  background: url(radar.png) center / cover;
  animation: 5s rotate linear infinite;
}

@keyframes rotate {
  100% {
    rotate: 1turn;
  }
}

.dot {
  position: absolute;
  left: calc(var(--x) * 100%);
  top: calc(var(--y) * 100%);
  /* border-radius: 50%; */
  width: 4px;
  height: 4px;
  margin: -2px;
  background: #cf5;
  box-shadow: 0 0 10px 5px rgba(100, 255, 0, 0.5);
  opacity: 0;
}

.dot-red {
  background: red;
}

.dex-banner{
  max-width:100%;
  padding-right : 10px;
} 

/**** END RADAR ****/

.viewer {
  width: 60%;
  float: right;
  margin-top: 83px;
}

rect.days-bar {
  /* stroke: 0;
  shape-rendering: geometricPrecision;
  opacity: 1; */
}

.chart svg {
  width: 100%;
  height: 100px;
  margin-top: 15px;
}

.progress {
  padding: 4px;
  background: #0096887a ; /*rgba(0, 0, 0, 0.25); */
  border-radius: 6px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
}

.progress-bar {
  height: 9px;
  border-radius: 4px;
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
  background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
  -webkit-transition: 0.4s linear;
  -moz-transition: 0.4s linear;
  -o-transition: 0.4s linear;
  transition: 0.4s linear;
  -webkit-transition-property: width, background-color;
  -moz-transition-property: width, background-color;
  -o-transition-property: width, background-color;
  transition-property: width, background-color;
  -webkit-box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.25), inset 0 1px rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.25), inset 0 1px rgba(255, 255, 255, 0.1);
}

.progress > .progress-bar {
  width: 25%;
  background-color: red;
}


.duration {
  color: #eb474c;
}

.text-highlight {
    color : #0eedd9 !important; 
}
.color-good{
  color : #0eedd9 ; /* rgb(0, 197, 154);  #0eedd9 */
}

.bg-good{
  background : #0eedd9;
}

.color-normal{
  color : yellow;
}

.color-alarm{
  color : yellow;
}

.color-bad{
  color : red;
}

.text-link {
  color : #1890ff
}

.text-token {
  color : #0eedd9 ; /* rgb(0, 197, 154);  #0eedd9 */
}

hr{
  border-color: #23273f;
}

/* .wallet-adapter-dropdown .wallet-adapter-button {
    height: 27px !important;
    line-height: 27px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    font-size: 14px !important;
} */
.wallet-adapter-button-start-icon {
  display : none !important;
}

.modal .wallet-adapter-button {
  display: inline-block;
}

.color-wallet {
  background-color: #512da8;
}

.bg-main{
  background: #060912;
}

.bg-head{
  background-color: rgb(12 16 30 / 1);
}

.bg-primary{
  background-color: rgb(27 29 40 / 1);
}

.border-head-bottom{
  border-bottom: 1px rgb(36 40 62 / 1) solid;
}

.bg-solana {
  background: linear-gradient(45deg, #9945ff, #14F195);
}
.btn-connect {
  border : 1px solid #fff;
  border-radius: 2px;
  padding : 4px 4px;
  /* background : #9945ff; */
}

@keyframes progress {
  0% { width: 0%; }
  100% { width: 100%; }
}

.progress-bar {
  animation: progress 10s linear infinite;
}

/* .table-tokens td, th {
  border-bottom: 1px solid #4b5563;
} */

.table-border-head td {
  border-bottom: 1px solid #4b5563;
  padding-top : 4px;
  padding-bottom : 4px;
}

.text-moonshot{
  color : #dfff18
}

.table-th-left th{
  text-align: left;
}

.bg-stick {
  background: #1b1d28;
}

.color-ads {
  color : #a4cf5e;
}
/*
input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 1rem;
  height: 1rem;
  border-radius: 50%; 
  border: 4px solid #cbd5e0; 
  outline: none;
  cursor: pointer;
  position: relative;
}

input[type="checkbox"]:checked {
  background-color: #0eedd9; 
  border-color: #0eedd9; 
}

input[type="checkbox"]:checked::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0.6rem;
  height: 0.6rem;
  border: solid #060912;
  border-width: 0 0.2rem 0.2rem 0;
  transform: translate(-50%, -50%) rotate(45deg);
}
*/